.pooja-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    min-height: 100vh; /* Ensures the content is centered */
    border-bottom: 1px solid black; /* Black line at the bottom */
    margin-bottom: 20px; /* Adds 20px spacing below the container */
  }
  
  .container-heading {
    font-size: 1.8rem;
    color: #05f;
    margin-bottom: 15px;
    text-align: center; /* Center heading text */
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns by default */
    gap: 20px;
    margin-top: 20px;
    width: 100%;
    max-width: 1200px; /* Ensures the grid is centered */
  }
  
  .grid-item {
    border: 2px solid black;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .grid-item img {
    width: 100%; /* Image takes full width of the card */
    height: 40vh;
    display: block;
  }
  
  .grid-item p {
    margin: 0;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1024px) {
    .grid-container {
      grid-template-columns: repeat(2, 1fr); /* 2 columns for medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns: repeat(1, 1fr); /* 1 column for small screens */
    }
  }
  