.contact-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
}

/* Left Side - Contact Info */
.contact-info {
  flex: 1;
  min-width: 300px;
  padding: 20px;
  color: #333;
}

.contact-heading {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.contact-description {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 20px;
}

.contact-details div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}

.contact-details svg {
  margin-right: 10px;
}

.social-icons {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.social-icons a {
  font-size: 24px;
  color: #333;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #0073e6;
}

/* Right Side - Contact Form */
.contact-form {
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

.contact-form label {
  font-size: 16px;
  margin-top: 10px;
  color: #333;
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
}

.send-button {
  background-color: #8b4513;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.send-button:hover {
  background-color: #5e2e1a;
}

/* Bottom Center - Contact Now Section */
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  /* background-color: #f4f4f9; */
  /* color: #333; */
  width: 100%;
  height: 30rem;
}

.contact-container h3 {
  font-size: 1.5rem;
  margin: 10px 0;
}

.contact-container h1 {
  font-size: 2.5rem;
  color: #8b4513;
  margin: 10px 0;
}

.contact-container h2 {
  font-size: 1.25rem;
  color: #555;
  margin: 10px 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-section {
    flex-direction: column;
  }

  .contact-container h3 {
    font-size: 1.25rem;
  }

  .contact-container h1 {
    font-size: 2rem;
  }

  .contact-container h2 {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .contact-container h3 {
    font-size: 1rem;
  }

  .contact-container h1 {
    font-size: 1.75rem;
  }

  .contact-container h2 {
    font-size: 0.9rem;
  }
}
