.circular-image-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; /* Allows wrapping for responsiveness */
    gap: 20px;
    padding: 20px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto; /* Center the row horizontally */
  }
  
  .circular-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 150px; /* Consistent width for each item */
  }
  
  .circular-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden; /* Ensures image fits within the circular shape */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ddd; /* Optional border around the image */
    background-color: #f0f0f0; /* Optional background */
  }
  
  .circular-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire circle */
  }
  
  .image-name {
    margin-top: 10px;
    font-size: 1rem;
    font-family: "Laila", Sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: #05f;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .circular-item {
      width: 120px; /* Smaller items on tablets */
    }
  
    .circular-image {
      width: 80px;
      height: 80px;
    }
  
    .image-name {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 768px) {
    .circular-image-row {
      gap: 15px;
    }
  
    .circular-item {
      width: 100px; /* Adjust item width on smaller screens */
    }
  
    .circular-image {
      width: 70px;
      height: 70px;
    }
  
    .image-name {
      font-size: 0.85rem; /* Adjust text size */
    }
  }
  
  @media (max-width: 480px) {
    .circular-item {
      width: 80px; /* Even smaller items on mobile */
    }
  
    .circular-image {
      width: 60px;
      height: 60px;
    }
  
    .image-name {
      font-size: 0.75rem;
    }
  }
  