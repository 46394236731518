/* General footer styling */
footer {
  display: flex;
  justify-content: space-between;
  background-color: #333;
  color: white;
  padding: 2rem;
  flex-wrap: wrap; /* Enable wrapping for responsiveness */
}

.footer-column {
  flex: 1;
  margin: 1rem;
  text-align: left;
  min-width: 200px; /* Ensure columns don't get too narrow */
}

.footer-column h3 {
  border-bottom: 2px solid white;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.footer-column p,
.footer-column ul,
.footer-column li {
  margin: 0.5rem 0;
}

.footer-column ul {
  list-style-type: none;
  padding-left: 0;
}

.footer-column ul li {
  margin-bottom: 0.5rem;
}

.footer-column ul li a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.footer-column ul li a:hover {
  text-decoration: underline;
}

/* Styling for social icons */
.social-icons {
  display: flex;
  flex-direction: column; /* Stack icons and text vertically */
  padding-left: 2rem;
}

.social-icons li {
  list-style: none;
  margin-bottom: 0.5rem;
}

.social-icons a {
  color: orange;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: green;
}

.icon {
  margin-right: 8px;
  color: rgb(248, 135, 7);
}

.icon:hover {
  color: green;
}

/* Responsive Design */
@media (max-width: 768px) {
  footer {
    flex-direction: column; /* Stack columns vertically */
    align-items: center;
    text-align: center; /* Center align text for smaller screens */
  }

  .footer-column {
    text-align: center;
    flex: none; /* Disable flex shrinking */
    width: 100%; /* Take full width */
    margin-bottom: 1rem;
  }

  .social-icons {
    padding-left: 0; /* Remove left padding on smaller screens */
    flex-direction: row; /* Show social icons in a row */
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .footer-column h3 {
    font-size: 1.2rem; /* Adjust header size for smaller screens */
  }

  .footer-column ul li a {
    font-size: 0.9rem; /* Adjust link font size */
  }

  .social-icons a {
    font-size: 1rem; /* Adjust icon size for smaller screens */
  }
}
