.pandit-banner {
  padding: 20px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.banner-title {
  margin-bottom: 20px;
}

.banner-title h1 {
  font-size: 30px;
  color: #05f;
  text-align: center;
}

/* Bottom Content Container */
.banner-content {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap; /* Ensures wrapping for responsiveness */
  width: 100%;
  max-width: 1200px; /* Keeps content from stretching too wide */
  margin: 0 auto;
}

.banner-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
}

.banner-image img {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.banner-text {
  flex: 2;
  max-width: 500px;
  text-align: left;
}

.banner-text p {
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}

/* Button Styling */
.call-button {
  width: 30rem;
  background-color: #e53935;
  color: white;
  padding: 10px 20px;
  border: 2px solid white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.call-button:hover {
  background-color: #c62828;
  color: #fff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .banner-content {
    flex-direction: column; /* Stack image and text vertically */
    align-items: center;
  }

  .banner-text {
    text-align: center;
    max-width: 100%; /* Allow text to use full width */
    padding: 0 10px; /* Add some padding for better spacing */
  }

  .banner-image {
    max-width: 250px; /* Adjust image size for smaller screens */
    margin-bottom: 20px; /* Space between image and text */
  }
}

@media (max-width: 480px) {
  .banner-title h1 {
    font-size: 24px;
  }

  .banner-text p {
    font-size: 14px;
  }

  .call-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .banner-image img {
    max-width: 200px; /* Further adjust image size for very small screens */
  }
}
